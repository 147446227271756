<template>
  <div>
    <b-card class="histogram-card custom-card">
      <b-row class="flex-nowrap d-flex align-items-center">
        <b-col cols="auto">
          <b-form-group class="mb-0" style="min-width: 300px">
            <DatasourceSelect v-model="datasourceSelected" mode="multiple" :autoLocationSelected="autoLocationSelected" />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-form-group class="mb-0">
            <option-chart :label="$t('option_chart')" :showScale="true" :shape="false" :options="typeList" :typeList="typeList" v-model="typeSelected" :nameComponent="$options.name" />
          </b-form-group>
        </b-col>
        <b-col cols="auto" class="ml-auto d-flex align-items-center">
          <SummaryData :chartData="chartData" />
          <Downloader :downloadImage="downloadImage" :downloadHtml="downloadHtml" :downloadCsv="downloadCsv" />
        </b-col>
      </b-row>
    </b-card>

    <b-overlay :show="showOverlay" variant="transparent" opacity="0.85" blur="2px" rounded="sm" no-fade>
      <HistogramChart ref="chart" :chart="chart" :showYear="showYear" :nameComponent="$options.name" />
    </b-overlay>

    <Timeplayer ref="timeplayer" :liveMode="liveMode" v-model="date" :moveToEnd="moveToEnd" />
    <AutoGroups :groupData="autoGroupData" :autoGroup="autoGroup" />
  </div>
</template>

<script>
const _ = require('lodash');
import PausePlayerMixin from '@/mixins/PausePlayerMixin';
import ChartYearMixin from '@/mixins/ChartYearMixin';
import ScaleMixin from '@/mixins/ScaleMixin';
import SelectDatasourceMixin from '@/mixins/SelectDatasourceMixin';
import Timeplayer from '../../common/Timeplayer.vue';
import DatasourceSelect from '../../common/DatasourceSelect/DatasourceSelect.vue';
import Downloader from '../../common/Downloader.vue';
import SummaryData from '../../common/SummaryData.vue';
import RadioGroup from '../../common/RadioGroup.vue';
import OptionChart from '../../common/OptionChartPlotly.vue';
import HistogramChart from './HistogramChartComponent.vue';
import AutoGroups from '../../common/AutoGroups.vue';
import { splitByLastIndex } from '@/utilities/StringUtility.js';
import { SelectedGroupMixin } from '@/mixins/GroupItemsMixin';
import { OPTION_PLOTLY_PALETTE } from '@/constants/colors';

const TYPE_LIST = [
  { value: 'group', text: 'group_title', icon: require('/static/images/histogram/group.svg').default },
  { value: 'overlay', text: 'overlay_title', icon: require('/static/images/histogram/overlay.svg').default },
  { value: 'stack', text: 'stack_title', icon: require('/static/images/histogram/stack.svg').default },
];

export default {
  name: 'histogram',
  components: { HistogramChart, Timeplayer, DatasourceSelect, Downloader, SummaryData, RadioGroup, AutoGroups, OptionChart },
  mixins: [SelectDatasourceMixin, ScaleMixin, PausePlayerMixin, SelectedGroupMixin, ChartYearMixin],
  data() {
    return {
      typeList: TYPE_LIST,
      chart: { data: [], layout: { barmode: this.typeSelected } },
      chartData: [],
      moveToEnd: 0,
      showOverlay: false,
      renderDate: [],
      autoLocationSelected: [],
      clearShowOverlay: null,
    };
  },
  created() {
    let autoGroup = _.cloneDeep(this.autoGroup);
    if (autoGroup) {
      this.autoLocationSelected = {
        select: this.getDataLocationItemByGroup(this.autoGroupData[autoGroup]),
      };
    }
  },
  computed: {
    typeSelected() {
      return this.$store.state.tabs[this.$options.name].typeSelected;
    },
    date: {
      get() {
        return this.$store.state.tabs[this.$options.name].date;
      },
      set(date) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { date });
      },
    },
    markerColor() {
      let colors = this.$store.state.tabs[this.$options.name].colors;
      colors = colors && colors.length > 0 ? colors : OPTION_PLOTLY_PALETTE.D3;
      return colors;
    },
    showYear() {
      return this.$store.state.tabs[this.$options.name].year;
    },
    showLegend() {
      return this.$store.state.tabs[this.$options.name].legend;
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject;
    },
    autoGroup() {
      return this.$store.state.tabs.histogram.autoGroup;
    },
    datasources() {
      return this.$store.state.datasource.datasources.filter((d) => d.type === 'timeseries');
    },
    autoGroupData() {
      let result = {};
      for (let i = 0; i < this.datasources.length; i++) {
        const metadata = this.$db[this.datasources[i].id];
        const autoGroupKeys = Object.keys(metadata.autoGroups);
        autoGroupKeys.map((key) => {
          if (!result[key]) result[key] = metadata.autoGroups[key].map((value) => value.toString().concat(`|${this.datasources[i].id}`));
          else result[key] = result[key].concat(metadata.autoGroups[key].map((value) => value.toString().concat(`|${this.datasources[i].id}`)));
        });
      }
      return result;
    },
    rotateChart() {
      return this.$store.state.tabs[this.$options.name].rotate;
    },
    liveMode() {
      try {
        let check = false;
        if (!this.datasources || !this.datasourceSelected) return false;
        let arryIdDatasources = [];
        this.datasources.map((data) => {
          arryIdDatasources.push(data.id);
        });
        arryIdDatasources = _.unionBy(arryIdDatasources);
        this.datasourceSelected.map((data) => {
          let indexData = arryIdDatasources.indexOf(data.datasource);
          if (indexData > -1 && this.datasources[indexData].live) {
            check = true;
            return check;
          }
        });
        return check;
      } catch { }
      return false;
    },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode;
    },
  },
  watch: {
    markerColor() {
      this.setChart();
    },
    showLegend() {
      this.setChart();
    },
    selectedGroup: {
      deep: true,
      handler() {
        this.setChart();
      },
    },
    datasourceSelected() {
      this.moveToEnd++;
      this.setChart();
    },
    typeSelected() {
      this.setChart();
    },
    scale() {
      this.setChart();
    },
    date() {
      this.setChart(true);
    },
    autoGroup: {
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal && !oldVal) return;
        if (oldVal && newVal === oldVal) {
          this.autoLocationSelected = {
            select: this.getDataLocationItemByGroup(this.autoGroupData[newVal]),
          }
        }
        else {
          this.autoLocationSelected = {
            unSelect: this.getDataLocationItemByGroup(this.autoGroupData[oldVal]),
            select: this.getDataLocationItemByGroup(this.autoGroupData[newVal]),
          }
        }
      }
    },
    countLiveMode() {
      this.setChart();
    },
  },
  methods: {
    async setChart() {
      try{
        let traces = [];
        let chartData = [];
        let traceCount = 0;
        // min date and max date (range case and single case)
        const minDate = this.date.length === 2 ? this.date[0] : this.date.length === 1 ? '0000-00-00 00:00:00' : '';
        const maxDate = this.date.length === 2 ? this.date[1] : this.date.length === 1 ? this.date[0] : '';
        //SAVE CURRENT DATE RENDERING
        this.renderDate = [minDate, maxDate];
        // Group by datasource
        const timeseriesDatasource = this.datasourceSelected.filter((ds) => ds.location);
        if (timeseriesDatasource.length !== 0) {
          const datasourceGrouped = _.groupBy(timeseriesDatasource, (d) => d.datasource);
          for (const datasourceId in datasourceGrouped) {
            const locationsItems = _.map(datasourceGrouped[datasourceId], (data) => {
              return data.location + '-' + data.item;
            });
            const records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, this.scale);
            if (!records.dates) continue;
            // Build traces and 'chartData'
            for (const locationItem of locationsItems) {
              const [location, item] = splitByLastIndex(locationItem, '-');
              const name = locationItem.replace('-', ' - ');
              const visible = this.selectedGroup && !this.selectedGroup.items.includes(location) ? 'legendonly' : true;
              const arrObjects = records[locationItem];
              const x = arrObjects ? arrObjects : [];
              traces.push({
                name,
                visible,
                x,
                type: 'histogram',
                marker: {
                  color: `${this.markerColor[traceCount % this.markerColor.length]}${this.typeSelected === 'overlay' ? '80' : 'ff'}`,
                  // line: { width: 2, color: this.markerColor[traceCount % this.markerColor.length] },
                },
              });
              traceCount++;
              chartData = chartData.concat(x);
            }
          }
        }
        this.chart = {
          data: traces,
          layout: {
            barmode: this.typeSelected,
            showlegend: this.showLegend,
            yaxis: {
              autorange: this.rotateChart ? 'reversed' : true,
            },
          },
        };
        this.chartData = chartData;
      } catch { }
    },
    async getDataCSV() {
      let dataCSV = '';
      let id = 'ID';
      let item = 'ITEM';
      let unit = 'UNIT';
      let dataLines = [];
      let lineCSV = '';
      let recordsDate = [];
      // Min date and max date (range case and single case)
      const minDate = this.date.length === 2 ? this.date[0] : this.date.length === 1 ? '0000-00-00 00:00:00' : '';
      const maxDate = this.date.length === 2 ? this.date[1] : this.date.length === 1 ? this.date[0] : '';
      const datasourceGrouped = _.groupBy(this.datasourceSelected, (d) => d.datasource);
      // calculator commma length
      let dataSort = {};
      for (const datasourceId in datasourceGrouped) {
        const dataItems = _.cloneDeep(datasourceGrouped[datasourceId]);
        let dataItemsSort;
        try {
          dataItemsSort = _.sortBy(dataItems, ['item', 'location']);
        } catch {
          dataItemsSort = dataItems;
        }
        dataSort[datasourceId] = dataItemsSort;
        const locationsItems = _.map(dataItemsSort, (data) => {
          return data.location + '-' + data.item;
        });
        const records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, this.scale);
        if (records.dates && records.dates != null) {
          recordsDate = recordsDate[0] && new Date(recordsDate[0]) > new Date(records.dates[0]) ? [...new Set([...records.dates, ...recordsDate])] : [...new Set([...recordsDate, ...records.dates])];
        }
      }
      // Group by datasource
      for (const datasourceId in dataSort) {
        const locationsItems = _.map(dataSort[datasourceId], (data) => {
          let unitTemp = ',';
          try {
            unitTemp = `,${this.$db[data.datasource].items[data.item].unit}`;
          } catch { }
          id += ',' + data.location;
          item += ',' + data.item;
          unit += unitTemp;
          return data.location + '-' + data.item;
        });
        const records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, this.scale);
        recordsDate.forEach((date, index) => {
          dataLines[index] = dataLines[index] && dataLines[index] != '' ? dataLines[index] : date;
          for (let m = 0; m < locationsItems.length; m++) {
            let id = records.dates.indexOf(date);
            if (id != -1) {
              dataLines[index] += ',' + records[locationsItems[m]][id];
            } else {
              dataLines[index] += ',';
            }
          }
        });
      }
      dataLines.forEach((line) => {
        lineCSV += line + '\r\n';
      });
      if (lineCSV != '') {
        dataCSV += id + '\r\n' + item + '\r\n' + unit + '\r\n' + lineCSV;
      }
      return dataCSV;
    },
    downloadHtml() {
      this.$refs.chart.asHtml(this.tempProject.name + '_Histogram');
    },
    downloadImage() {
      this.$refs.chart.asImage(this.tempProject.name + '_Histogram');
    },
    async downloadCsv() {
      let data = await this.getDataCSV();
      this.$refs.chart.asCSV(this.tempProject.name + '_Histogram', data);
    },
    getDataLocationItemByGroup(groupData) {
      if (!groupData) return [];
      let result = [];
      for (let i = 0; i < groupData.length; i++) {
        const [locationItems, datasourceId] = splitByLastIndex(groupData[i], '|');
        const [location, item] = splitByLastIndex(locationItems, '*');
        result.push({
          datasource: datasourceId,
          item,
          location,
        });
      }
      return result;
    },
  },
};
</script>
</script>
<style lang="scss" scoped>
.percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-icon {
  width: 50px;
  height: 50px;
}

.loading-icon {
  -webkit-animation: loading 1s infinite;
  -moz-animation: loading 1s infinite;
  -ms-animation: loading 1s infinite;
  animation: loading 1s infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes loading {
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btn-flat-custom {
  background: #397bff;
  color: #fff;
}

.btn-flat-custom:hover {
  opacity: 0.9;
}
</style>
