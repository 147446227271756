<template>
  <b-card no-body class="histogram-chart-card">
    <b-card-body>
      <div class="histogram-chart" id="histogram-plotly-chart" ref="chart" v-show="showChart"></div>
      <div class="histogram-chart" v-show="!showChart">
        <EmptyChart :description="$t('tips_histogram')" />
      </div>
      <ChartYearText ref="refChartYearText" v-if="showYear && showChart && $parent.$parent.yearText" :text="$parent.$parent.yearText" />
    </b-card-body>
  </b-card>
</template>

<script>
const _ = require('lodash')
import Plotly from 'plotly.js-dist'
import DownloadMixin from '@/mixins/DownloadMixin'
import EmptyChart from '../../common/EmptyChart.vue'
import ChartYearText from '../../common/ChartYearText.vue'
import i18n from '@/libs/i18n'
import { locale } from '@/scripts/plotly/locale.js'
import { CustomLayout } from '@/mixins/ThemeMixin.js'

const LAYOUT = {
  hovermode: 'closest',
  yaxis: { automargin: true },
  xaxis: { automargin: true },
}
const CONFIG = {
  displaylogo: false,
  responsive: true,
  editable: true,
  modeBarButtonsToRemove: ['toImage', 'select2d', 'lasso2d'],
}

export default {
  props: ['chart', 'showYear', 'nameComponent'],
  mixins: [DownloadMixin, CustomLayout],
  components: { EmptyChart, ChartYearText, locale },
  data() {
    return {
      funcEventPlotlyRelayout: null,
    }
  },
  mounted() {
    this.updateChart()
    // Listen to div resize => chart resize
    this.$nextTick(() => {
      if (this.$refs.chart) {
        new ResizeObserver(() => {
          if (document.getElementsByClassName('histogram-chart')[0] && this.$refs.chart && this.$refs.chart.style.display !== 'none') {
            Plotly.Plots.resize(this.$refs.chart)
          }
        }).observe(this.$refs.chart)
      }
    })
  },

  computed: {
    showChart() {
      return this.chart && this.chart.data.length
    },
    locale() {
      return i18n.locale
    },
    rotateChart() {
      return this.$store.state.tabs[this.nameComponent].rotate
    },
    editable() {
      return this.$store.state.tabs[this.nameComponent].editable
    },
    chartFontFamily() {
      return this.$store.state.tabs[this.nameComponent].chartFontFamily
    },
    chartTextSize() {
      return this.$store.state.tabs[this.nameComponent].chartTextSize
    },
    chartTextColor() {
      return this.$store.state.tabs[this.nameComponent].chartTextColor
    },
    chartFont() {
      let font = {}
      try {
        if (this.chartFontFamily) {
          font.family = this.chartFontFamily
        }
        if (this.chartTextSize) {
          font.size = this.chartTextSize
        }
        if (this.chartTextColor) {
          font.color = this.chartTextColor
        }
      } catch {}
      return font
    },
    chartLayout() {
      return this.$store.state.tabs.histogram.chartLayout
    },
  },
  watch: {
    chart: {
      deep: true,
      handler() {
        this.updateChart(true)
      },
    },
    locale() {
      if (this.chart.data.length > 0) {
        this.updateChart()
      }
    },
    rotateChart() {
      let rotate = this.rotateChart ? 'reversed' : true
      this.chart.layout.yaxis.autorange = rotate
      this.updateChart(false)
    },
    editable() {
      if (this.chart.data.length > 0) {
        this.updateChart()
      }
    },
    chartFont: {
      deep: true,
      handler() {
        if (this.chartFont) {
          this.updateChartLayout()
        }
      },
    },
    $route: {
      deep: true,
      handler() {
        try {
          if (this.$route.name == 'histogram') {
            this.eventPlotlyLayout()
          }
        } catch {}
      },
    },
  },
  methods: {
    eventPlotlyLayout() {
      const self = this
      try {
        if (self.funcEventPlotlyRelayout) {
          clearTimeout(self.funcEventPlotlyRelayout)
        }
        self.funcEventPlotlyRelayout = setTimeout(() => {
          let plotlyElement = document.getElementById('histogram-plotly-chart')
          if (plotlyElement) {
            plotlyElement.on('plotly_relayout', (data) => {
              try {
                let layoutOrigin = plotlyElement.layout
                let layoutTemp = {
                  xaxis: {},
                  yaxis: {},
                }
                if (layoutOrigin.title) {
                  layoutTemp.title = layoutOrigin.title
                }
                if (layoutOrigin.xaxis && layoutOrigin.xaxis.title) {
                  layoutTemp.xaxis.title = layoutOrigin.xaxis.title
                }
                if (layoutOrigin.yaxis && layoutOrigin.yaxis.title) {
                  layoutTemp.yaxis.title = layoutOrigin.yaxis.title
                }
                if (self.$route && self.$route.name == 'histogram') {
                  self.$store.commit(`tabs/SET_HISTOGRAM`, { chartLayout: layoutTemp })
                }
              } catch {}
            })
          }
        }, 1000)
      } catch {}
    },
    updateChart(isUpdate = false) {
      let _config = CONFIG
      _config.locale = i18n.locale
      _config = _.merge(_config, { editable: this.editable })
      let layoutTemp = {}
      try {
        if (this.chartFont) {
          layoutTemp = _.merge(layoutTemp, { font: this.chartFont })
        }
        if (this.chartLayout) {
          layoutTemp = _.merge(layoutTemp, this.chartLayout)
        }
      } catch {}
      if (isUpdate) {
        let layout = _.merge({}, this.$refs.chart.layout, this.themeLayout, LAYOUT, this.chart.layout, layoutTemp)
        Plotly.react(this.$refs.chart, this.chart.data, layout, _config)
      } else {
        let layout = _.merge({}, this.themeLayout, LAYOUT, this.chart.layout, layoutTemp)
        if (this.$refs.chart && this.$refs.chart.layout) {
          if (this.$refs.chart.layout.legend) {
            layout.legend = this.$refs.chart.layout.legend
          }
          if (this.$refs.chart.layout.title) {
            layout.title = this.$refs.chart.layout.title
          }
          if (this.$refs.chart.layout.xaxis.title) {
            layout.xaxis.title = this.$refs.chart.layout.xaxis.title
          }
          if (this.$refs.chart.layout.yaxis.title) {
            layout.yaxis.title = this.$refs.chart.layout.yaxis.title
          }
        }
        Plotly.newPlot(this.$refs.chart, this.chart.data, layout, _config)
        this.eventPlotlyLayout()
      }
    },
    updateChartLayout() {
      // setTimeout(() => {
      try {
        let layout = this.$refs.chart.layout
        let layoutTemp = {}
        try {
          if (this.chartFont) {
            layoutTemp = _.merge(layoutTemp, { font: this.chartFont })
          }
          if (this.chartLayout) {
            layoutTemp = _.merge(layoutTemp, this.chartLayout)
          }
        } catch {}
        layout = _.merge(layout, layoutTemp)
        Plotly.relayout(this.$refs.chart, layout)
        // Plotly.react(this.$refs.chart, this.$refs.chart.data, layout);
      } catch {}
      // }, 300);
    },
  },
}
</script>

<style scoped>
.histogram-chart-card .card-body {
  padding-top: 0;
}

.histogram-chart {
  height: calc(100vh - 30px - 90.3px - 90px - 2rem - 36px);
}
</style>
